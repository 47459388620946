import React from 'react'

const AppFooter = () => {
    const {origin} = window.location

    let footer = `copyright © ${new Date().getFullYear()}`
    switch (origin) {
        case 'https://www.grupseld.com':
        case 'https://grupseld.com':
        case 'https://www.app.perucontable.net':
        case 'https://app.perucontable.net':
            footer = `© ${new Date().getFullYear()}. Desarrollado y Soportado por`
            break
    }

    return (
        <div className="app-footer">
            <div className="app-footer__inner">
                <div className="app-footer-left">
                    <span>{footer}<a href="https://alternativascontables.pe" target="_blank"> Alternativas Contables S.R.L.</a></span>
                </div>
                <div className="app-footer-right">
                    v<strong>4.5.0107 </strong>
                </div>
            </div>
        </div>
    )
}
export default AppFooter
